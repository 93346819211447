.wrapper{
    overflow: hidden;
    background-color: #ebfffa;
    text-align: center;
}

.grid{
    display: grid;
    grid-template-columns: auto auto;
    margin: auto;
    margin-top: 4rem;
    width: 80vw;
    max-width: 1000px;
    text-align: left;
}

.footerTop{
    margin-top: 2rem;
    width: 100%;
    height: 20vh;
    min-height: 40px;
    max-height: 100px;
    background-color: white;
    
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.footerTopBackground{
    background-color: #ebfffa;
}



@media screen and (max-width: 700px) {
    .footerTop{
        height: 10vh;
    }
    .grid{
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        margin: auto;
        margin-top: 4rem;
        width: fit-content;
        max-width: 700px;
        text-align: center;
    }
    .title{
        margin: auto;
        margin-top: 2rem;
    }
}