* {
    --primary-color: #00BFA6;
    --primary-color-dark: #00a790;
    --secondary-color: #FF7136;
    --white-color: #FFF;

    --primary-gradient: linear-gradient(to left, var(--primary-color), var(--secondary-color));

    --blue-gradient: linear-gradient(var(--light-blue), var(--dark-blue));

    color: black;
}

html{
    scroll-behavior: smooth;
}

body {
    background-color: var(--white-color);
    padding: 0px;
    margin: 0px;
    overflow-x: hidden;
}

@media only screen and (max-width: 680px) {
    body {
        overflow-x: visible;
    }
}

button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.button{
    background-color: var(--primary-color);
    font-family: 'Roboto', 'Arial';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding: 6px 20px 6px 20px;
    border-radius: 20px;
    box-shadow: 0px 1px 4px 1px #a0a0a0;
    color: white;
    cursor: pointer;
    transition: 0.3s, smooth;
}

.button:hover{
    background-color: var(--primary-color-dark);
    box-shadow: 0px 1px 8px 1px #8f8f8f;
}

h1 {
    font-family: 'Roboto', 'Arial';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 140%;
    letter-spacing: 0.025em;
    width: fit-content;
    max-width: 100%;

    margin: 0px;
}

h2 {
    font-family: 'Roboto', 'Arial';
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 140%;
    letter-spacing: 0.025em;
    width: fit-content;
    max-width: 100%;

    margin: 0px;
}

h3 {
    font-family: 'Roboto', 'Arial';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.05em;
    width: fit-content;
    max-width: 100%;

    margin: 0px;
}

.p {
    font-family: 'Roboto', 'Times New Roman';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.05em;

    margin: 0px;
}

.p-info {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: lighter;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.05em;
    border-bottom: 1px solid white;

    width: fit-content;
    margin: 0px;
    margin-bottom: 2rem;
}

a {
    font-family: 'Roboto', 'Arial';
    text-decoration: none;
}

.a1 {
    font-family: 'Roboto', 'Arial';
    color: var(--primary-color-dark);
    font-size: 14px;
    text-decoration: none;
}

.flex-grow {
    flex-grow: 1;
}

.p-mail{
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: lighter;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.05em;
    border-bottom: 1px solid white;

    width: fit-content;
    margin: 0px;
    margin-bottom: 2rem;
}

.text-gradient {
    background: var(--primary-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.w90{
    width: 90%;
    margin: auto;
}

.ma{
    margin: auto;
}

.left{
    margin-right: auto;
    margin-left: 0px;
}

.right{
    margin-left: auto;
    margin-right: 0px;
}

.top{
    margin-bottom: auto;
    margin-top: 0px;
}

.bottom{
    margin-top: auto;
    margin-bottom: 0px;
}

@media screen and (max-width: 700px) {
    .left{ margin: 0px; }
    .right{ margin: 0px; }
    .top{ margin: 0px; }
    .bottom{ margin: 0px; }
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 0.25rem;
}

.m-2 {
    margin: 0.5rem;
}

.m-3 {
    margin: 0.75rem;
}

.m-4 {
    margin: 1rem;
}

.m-5 {
    margin: 1.25rem;
}

.m-6 {
    margin: 1.5rem;
}

.m-8 {
    margin: 2rem;
}

.m-10 {
    margin: 2.5rem;
}

.m-12 {
    margin: 3rem;
}

.m-16 {
    margin: 4rem;
}

.m-20 {
    margin: 5rem;
}

.m-24 {
    margin: 6rem;
}

.m-32 {
    margin: 8rem;
}

.m-40 {
    margin: 10rem;
}

.m-48 {
    margin: 12rem;
}

.m-56 {
    margin: 14rem;
}

.m-64 {
    margin: 16rem;
}

.m-auto {
    margin: auto;
}

.m-px {
    margin: 1px;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

.mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
}

.my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
}

.my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
}

.my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
}

.my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
}

.my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
}

.my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
}

.mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
}

.my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
}

.mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
}

.my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
}

.mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
}

.my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
}

.mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
}

.my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
}

.mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.my-px {
    margin-top: 1px;
    margin-bottom: 1px;
}

.mx-px {
    margin-left: 1px;
    margin-right: 1px;
}

.-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
}

.-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}

.-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
}

.-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
}

.-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
}

.-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
}

.-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
}

.-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
}

.-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
}

.-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
}

.-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
}

.-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
}

.-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
}

.-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
}

.-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
}

.-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
}

.-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
}

.-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
}

.-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
}

.-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
}

.-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
}

.-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
}

.-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
}

.-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
}

.-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
}

.-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
}

.-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
}

.-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
}

.-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
}

.-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
}

.-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
}

.-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
}

.-mx-px {
    margin-left: -1px;
    margin-right: -1px;
}

.mt-0 {
    margin-top: 0;
}

.mr-0 {
    margin-right: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.ml-0 {
    margin-left: 0;
}

.mt-1 {
    margin-top: 0.25rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mb-1 {
    margin-bottom: 0.25rem;
}

.ml-1 {
    margin-left: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.mt-3 {
    margin-top: 0.75rem;
}

.mr-3 {
    margin-right: 0.75rem;
}

.mb-3 {
    margin-bottom: 0.75rem;
}

.ml-3 {
    margin-left: 0.75rem;
}

.mt-4 {
    margin-top: 1rem;
}

.mr-4 {
    margin-right: 1rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.ml-4 {
    margin-left: 1rem;
}

.mt-5 {
    margin-top: 1.25rem;
}

.mr-5 {
    margin-right: 1.25rem;
}

.mb-5 {
    margin-bottom: 1.25rem;
}

.ml-5 {
    margin-left: 1.25rem;
}

.mt-6 {
    margin-top: 1.5rem;
}

.mr-6 {
    margin-right: 1.5rem;
}

.mb-6 {
    margin-bottom: 1.5rem;
}

.ml-6 {
    margin-left: 1.5rem;
}

.mt-8 {
    margin-top: 2rem;
}

.mr-8 {
    margin-right: 2rem;
}

.mb-8 {
    margin-bottom: 2rem;
}

.ml-8 {
    margin-left: 2rem;
}

.mt-10 {
    margin-top: 2.5rem;
}

.mr-10 {
    margin-right: 2.5rem;
}

.mb-10 {
    margin-bottom: 2.5rem;
}

.ml-10 {
    margin-left: 2.5rem;
}

.mt-12 {
    margin-top: 3rem;
}

.mr-12 {
    margin-right: 3rem;
}

.mb-12 {
    margin-bottom: 3rem;
}

.ml-12 {
    margin-left: 3rem;
}

.mt-16 {
    margin-top: 4rem;
}

.mr-16 {
    margin-right: 4rem;
}

.mb-16 {
    margin-bottom: 4rem;
}

.ml-16 {
    margin-left: 4rem;
}

.mt-20 {
    margin-top: 5rem;
}

.mr-20 {
    margin-right: 5rem;
}

.mb-20 {
    margin-bottom: 5rem;
}

.ml-20 {
    margin-left: 5rem;
}

.mt-24 {
    margin-top: 6rem;
}

.mr-24 {
    margin-right: 6rem;
}

.mb-24 {
    margin-bottom: 6rem;
}

.ml-24 {
    margin-left: 6rem;
}

.mt-32 {
    margin-top: 8rem;
}

.mr-32 {
    margin-right: 8rem;
}

.mb-32 {
    margin-bottom: 8rem;
}

.ml-32 {
    margin-left: 8rem;
}

.mt-40 {
    margin-top: 10rem;
}

.mr-40 {
    margin-right: 10rem;
}

.mb-40 {
    margin-bottom: 10rem;
}

.ml-40 {
    margin-left: 10rem;
}

.mt-48 {
    margin-top: 12rem;
}

.mr-48 {
    margin-right: 12rem;
}

.mb-48 {
    margin-bottom: 12rem;
}

.ml-48 {
    margin-left: 12rem;
}

.mt-56 {
    margin-top: 14rem;
}

.mr-56 {
    margin-right: 14rem;
}

.mb-56 {
    margin-bottom: 14rem;
}

.ml-56 {
    margin-left: 14rem;
}

.mt-64 {
    margin-top: 16rem;
}

.mr-64 {
    margin-right: 16rem;
}

.mb-64 {
    margin-bottom: 16rem;
}

.ml-64 {
    margin-left: 16rem;
}

.mt-auto {
    margin-top: auto;
}

.mr-auto {
    margin-right: auto;
}

.mb-auto {
    margin-bottom: auto;
}

.ml-auto {
    margin-left: auto;
}

.mt-px {
    margin-top: 1px;
}

.mr-px {
    margin-right: 1px;
}

.mb-px {
    margin-bottom: 1px;
}

.ml-px {
    margin-left: 1px;
}

.p-0 {
    padding: 0;
}

.p-1 {
    padding: 0.25rem;
}

.p-2 {
    padding: 0.5rem;
}

.p-3 {
    padding: 0.75rem;
}

.p-4 {
    padding: 1rem;
}

.p-5 {
    padding: 1.25rem;
}

.p-6 {
    padding: 1.5rem;
}

.p-8 {
    padding: 2rem;
}

.p-10 {
    padding: 2.5rem;
}

.p-12 {
    padding: 3rem;
}

.p-16 {
    padding: 4rem;
}

.p-20 {
    padding: 5rem;
}

.p-24 {
    padding: 6rem;
}

.p-32 {
    padding: 8rem;
}

.p-40 {
    padding: 10rem;
}

.p-48 {
    padding: 12rem;
}

.p-56 {
    padding: 14rem;
}

.p-64 {
    padding: 16rem;
}

.p-px {
    padding: 1px;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
}

.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
}

.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
}

.py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
}

.py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
}

.py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
}

.py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
}

.px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
}

.py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
}

.px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
}

.py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
}

.px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
}

.py-px {
    padding-top: 1px;
    padding-bottom: 1px;
}

.px-px {
    padding-left: 1px;
    padding-right: 1px;
}

.pt-0 {
    padding-top: 0;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pl-0 {
    padding-left: 0;
}

.pt-1 {
    padding-top: 0.25rem;
}

.pr-1 {
    padding-right: 0.25rem;
}

.pb-1 {
    padding-bottom: 0.25rem;
}

.pl-1 {
    padding-left: 0.25rem;
}

.pt-2 {
    padding-top: 0.5rem;
}

.pr-2 {
    padding-right: 0.5rem;
}

.pb-2 {
    padding-bottom: 0.5rem;
}

.pl-2 {
    padding-left: 0.5rem;
}

.pt-3 {
    padding-top: 0.75rem;
}

.pr-3 {
    padding-right: 0.75rem;
}

.pb-3 {
    padding-bottom: 0.75rem;
}

.pl-3 {
    padding-left: 0.75rem;
}

.pt-4 {
    padding-top: 1rem;
}

.pr-4 {
    padding-right: 1rem;
}

.pb-4 {
    padding-bottom: 1rem;
}

.pl-4 {
    padding-left: 1rem;
}

.pt-5 {
    padding-top: 1.25rem;
}

.pr-5 {
    padding-right: 1.25rem;
}

.pb-5 {
    padding-bottom: 1.25rem;
}

.pl-5 {
    padding-left: 1.25rem;
}

.pt-6 {
    padding-top: 1.5rem;
}

.pr-6 {
    padding-right: 1.5rem;
}

.pb-6 {
    padding-bottom: 1.5rem;
}

.pl-6 {
    padding-left: 1.5rem;
}

.pt-8 {
    padding-top: 2rem;
}

.pr-8 {
    padding-right: 2rem;
}

.pb-8 {
    padding-bottom: 2rem;
}

.pl-8 {
    padding-left: 2rem;
}

.pt-10 {
    padding-top: 2.5rem;
}

.pr-10 {
    padding-right: 2.5rem;
}

.pb-10 {
    padding-bottom: 2.5rem;
}

.pl-10 {
    padding-left: 2.5rem;
}

.pt-12 {
    padding-top: 3rem;
}

.pr-12 {
    padding-right: 3rem;
}

.pb-12 {
    padding-bottom: 3rem;
}

.pl-12 {
    padding-left: 3rem;
}

.pt-16 {
    padding-top: 4rem;
}

.pr-16 {
    padding-right: 4rem;
}

.pb-16 {
    padding-bottom: 4rem;
}

.pl-16 {
    padding-left: 4rem;
}

.pt-20 {
    padding-top: 5rem;
}

.pr-20 {
    padding-right: 5rem;
}

.pb-20 {
    padding-bottom: 5rem;
}

.pl-20 {
    padding-left: 5rem;
}

.pt-24 {
    padding-top: 6rem;
}

.pr-24 {
    padding-right: 6rem;
}

.pb-24 {
    padding-bottom: 6rem;
}

.pl-24 {
    padding-left: 6rem;
}

.pt-32 {
    padding-top: 8rem;
}

.pr-32 {
    padding-right: 8rem;
}

.pb-32 {
    padding-bottom: 8rem;
}

.pl-32 {
    padding-left: 8rem;
}

.pt-40 {
    padding-top: 10rem;
}

.pr-40 {
    padding-right: 10rem;
}

.pb-40 {
    padding-bottom: 10rem;
}

.pl-40 {
    padding-left: 10rem;
}

.pt-48 {
    padding-top: 12rem;
}

.pr-48 {
    padding-right: 12rem;
}

.pb-48 {
    padding-bottom: 12rem;
}

.pl-48 {
    padding-left: 12rem;
}

.pt-56 {
    padding-top: 14rem;
}

.pr-56 {
    padding-right: 14rem;
}

.pb-56 {
    padding-bottom: 14rem;
}

.pl-56 {
    padding-left: 14rem;
}

.pt-64 {
    padding-top: 16rem;
}

.pr-64 {
    padding-right: 16rem;
}

.pb-64 {
    padding-bottom: 16rem;
}

.pl-64 {
    padding-left: 16rem;
}

.pt-px {
    padding-top: 1px;
}

.pr-px {
    padding-right: 1px;
}

.pb-px {
    padding-bottom: 1px;
}

.pl-px {
    padding-left: 1px;
}