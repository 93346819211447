.navbar{
    position: relative;
    
    overflow: hidden;
    width: 100%;
    height: fit-content;
    background-color: white;
    box-shadow: 0px 1px 4px 1px #b8b8b8;
}

.navbarWrapper{
    max-width: 1200px;
    margin: auto;

    display: grid;
    grid-template-columns: auto auto;
}

.left{
    text-align: right;
    margin: auto;
    margin-right: 4rem;

    display: grid;
    grid-gap: 2rem;
    grid-template-columns: auto auto;

}

.link{
    text-decoration: none;
    margin: auto;
    transition: 0.3s, smooth;
}

.linkText{
    font-family: 'Roboto', 'Times New Roman';
    font-style: normal;
    color: #585858;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s, smooth;
}

.linkText:hover{
    color: black;
}

.landingWrapper{
    width: 100%;
    height: fit-content;
    min-height: 250px;
    max-height: 350px;
    background-color: #ebfffa;
}

.landing{
    width: 60vw;
    max-width: 1000px;
    margin: auto;
    padding-top: 5rem;
    padding-bottom: 5rem;

    display: grid;
    grid-template-columns: 40vw 20vw;
    column-gap: 2rem;

}

.landingBottom{
    width: 100%;
    height: 20vh;
    min-height: 40px;
    max-height: 100px;
    background-color: #ebfffa;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.landingLeft{
    text-align: center;
}

.header{
    width: 100%;
    max-width: 500px;
}

.actionButton{
    width: 9rem;
    height: 2.8rem;
    border-radius: 1.4rem;
    font-size: 16px;
}

.truck{
    height: 100px;
    margin: auto;
}

@media screen and (max-width: 750px) {
    .landing{
        width: 80vw;
        grid-template-columns: 80vw;
        grid-template-rows: auto auto;
        padding-top: 3rem;
        padding-bottom: 2rem;
    }
    .landingWrapper{
        width: 100%;
        min-height: 75vh;
        height: fit-content;
        max-height: none;
        background-color: #ebfffa;
    }
    .landingBottom{
        height: 10vh;
    }
    .header{
        width: 80vw;
        max-width: 700px;
        text-align: center;
    }
    .landingLeft{
        margin-top: 3.5rem;
        display: grid;
        grid-template-rows: auto auto;
    }
}