.grid{
    margin: 0 10vw 3rem 10vw;
    padding-top: 1.5rem;
    max-width: 80vw;
    overflow: hidden;

    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(160px, 160px) );
    text-align: center;
    justify-content: center;
    column-gap: 20px;
}

.card{
    position: relative;
    min-width: 160px;
    justify-content: center;
    /*margin: 0 20px 0 20px;
    transition: smooth, 0.3s;*/
    margin-bottom: 1rem;
}



.image{
    height: 120px;
    width: 120px;
    margin: auto;
    border-radius: 61px;
    object-fit: cover;
    border: solid 2px var(--primary-color);
}

.name{
    font-family: 'Roboto';
    font-size: 1rem;
}

.title{
    font-family: 'Roboto';
    font-size: 0.84rem;
    font-weight: 400;
    color: rgb(99, 99, 99);
}

@media screen and (min-width: 1500px) {
    .subtitle{
        margin: auto;
        max-width: 800px;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .grid{
        border-top: 1px solid white;
        margin: auto;
        padding-top: 2rem;
        max-width: 1200px;
    }
}