.todoSVG{
    width: 100%;
    height: auto;
    max-height: 160px;
    margin-top: auto;
    margin-bottom: 0px;
}

.illustrationsWrapper{
    display: grid;
    grid-template-rows: 200px auto auto auto;
    margin-bottom: 2rem;
}

.illustrations{
    max-width: 1000px;
    width: 90vw;
    margin: auto;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(180px, 28%) );
    column-gap: 5rem;
    justify-content: center;
}

.illustrationsText{
    margin: auto;
    margin-top: 2rem;
    width: 90%;
    font-size: 22px;
    font-weight: 300;
    color: #414141;
    
}

@media screen and (max-width: 700px) {
    .illustrations{
        max-width: 80vw;
        margin: auto;
        grid-template-columns: 80vw;
        grid-template-rows: auto auto auto;
        grid-gap: 3rem;
    }
}